import React from "react";
import ReactLoading from "react-loading";
import Design from "./LoadingScreen.module.css";

export default function LoadingScreen() {
  return (
    <div className={Design.loadingScreenContainer}>
      <div className={Design.loadingContainer}>
        <ReactLoading
          type={"spinningBubbles"}
          color={"#000"}
          height={60}
          width={60}
        />
        <h1>Loading...</h1>
      </div>
    </div>
  );
}
