import React from "react";
import { useNavigate } from "react-router-dom";
import testImageTwo from "../assets/images/testImageTwo.svg";
import Design from "./error.module.css";

export default function PageNotFound(props) {
  const { page } = props;
  let navigate = useNavigate();

  const goBackButton = () =>{
    if(page === "invalidId"){
      navigate(-1)
    }else{
      navigate(-1)
    }
  }

  return (
    <div className={Design.pageNotFoundPage}>
      <div className={Design.pageNotFoundContainer}>
        <img src={testImageTwo} alt="testImageTwo" style={{marginBottom: "30px"}} />
        <h3>Something went wrong.</h3>
        <p>Sorry, we can't find the page you're looking for.</p>
          <button onClick={goBackButton}>Go Back</button>
      </div>
    </div>
  );
}
