import React from 'react';
import testImageOne from "../assets/images/testImageOne.svg";
import Design from "./error.module.css";

export default function NetworkError() {

  const reloadPage = (event) => {
    event.preventDefault();
    window.location.reload();
  };

  return (
    <div className={Design.networkErrorPage}>
        <div className={Design.networkErrorContainer}>
            {/* <i class="fa-solid fa-triangle-exclamation"></i> */}
            <img src={testImageOne} alt="testImageOne"  />
            <h2 >Server Unreachable</h2>
            <p >This is may be due to server maintenance or a temporary network issue. Please try again later.</p>
            <button onClick={reloadPage}>Retry</button>
        </div>
    </div>
  )
}
