import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../utils/applicationConstants";
import "./Profile.css";
import { useParams } from "react-router-dom";
import NetworkError from "../error/NetworkError";
import PageNotFound from "../error/PageNotFound";
import LoadingScreen from "../components/LoadingScreen";
import ProfileImage from "./ProfileImage";

export default function Profile() {
  const { userId } = useParams();
  const [profileData, setProfileData] = useState([]);
  const [socialMediaSection, setSocialMediaSection] = useState(true);
  const [networkError, setNetworkError] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);
  const [loading, setLoading] = useState(false);

  const data = {
    authToken: "123",
    id: userId,
  };

  function changeColor(colorSet) {
    const root = document.documentElement;
    {
      colorSet?.bg_color !== null &&
        root.style.setProperty("--background-color", colorSet?.bg_color);
    }
    {
      colorSet?.fg_color1 !== null &&
        root.style.setProperty(" --first-gradient", colorSet?.fg_color1);
    }
    {
      colorSet?.fg_color2 !== null &&
        root.style.setProperty("--second-gradient", colorSet?.fg_color2);
    }
    {
      colorSet?.icon_color !== null &&
        root.style.setProperty("--icon-color", colorSet?.icon_color);
    }
    {
      colorSet?.text_color !== null &&
        root.style.setProperty("--text-color", colorSet?.text_color);
    }
  }

  const onLoadProfile = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/profiles/get`,
        data
      );

      if (response.data.message === "Error Getting Details") {
        setNotFoundError(true);
      }
      setProfileData(response.data?.data);
      const socialMediaHandle = response.data?.data?.social_handles;
      if (
        socialMediaHandle?.facebook === null &&
        socialMediaHandle?.instagram === null &&
        socialMediaHandle?.snapchat === null &&
        socialMediaHandle?.telegram === null &&
        socialMediaHandle?.threads === null &&
        socialMediaHandle?.twitter === null &&
        socialMediaHandle?.whatsapp === null &&
        profileData?.website === null
      ) {
        setSocialMediaSection(false);
      } else {
        setSocialMediaSection(true);
      }

      changeColor(response.data?.data?.display_settings);

      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.error("Error:", error);

      if (error.message === "Network Error") {
        setNetworkError(true);
      }
      if (error.message === "Request failed with status code 404") {
        setNotFoundError(true);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  useEffect(() => {
    setLoading(true);
    onLoadProfile();
  }, []);

  const createVCFName = () => {
    let vcfname = "";
    if (profileData.last_name) {
      vcfname += profileData.last_name + ";";
    } else {
      vcfname += ";";
    }
    if (profileData.first_name) {
      vcfname += profileData.first_name + ";";
    } else {
      vcfname += ";";
    }

    if (profileData.middle_name) {
      vcfname += profileData.middle_name + ";;;";
    } else {
      vcfname += ";;;";
    }

    return vcfname;
  };

  function downloadVCF(vcfname) {
    // Create vCard data
    const vcfData = `
BEGIN:VCARD
VERSION:3.0
FN:${profileData.first_name}
N:${vcfname}
ORG:${profileData.company}
TEL:${profileData.contact_number}
EMAIL:${profileData.email}
END:VCARD
`;

    // Create a Blob with the VCF data
    const blob = new Blob([vcfData], { type: "text/vcard" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "contact.vcf";

    // Trigger a click event on the link to initiate the download
    downloadLink.click();

    // Clean up: revoke the URL to release resources
    URL.revokeObjectURL(url);
  }

  const saveContactBtn = () => {
    const vcfname = createVCFName();
    if (vcfname) {
      downloadVCF(vcfname);
    }
  };

  return (
    <div>
      <div style={{ display: loading ? "none" : "block" }}>
        <div
          className="profile_page"
          style={{ display: networkError ? "none" : "block" }}
        >
          <div className="profile_body">
            <div className="profile_background"></div>

            <div className="profile">
              <div className="profile_info_content">
                <ProfileImage
                  profileImageUrl={profileData?.photo_url}
                  userId={userId}
                />

                <h1 className="profile_name">
                  {profileData?.first_name}{" "}
                  {profileData?.last_name ? profileData?.last_name : null}
                </h1>
                <p className="profile_desc">{profileData?.designation}</p>
                <p className="profile_desc">{profileData?.company}</p>
              </div>

              <div
                className="profile_icons_container"
                style={{ display: socialMediaSection ? "flex" : "none" }}
              >
                {profileData?.social_handles?.facebook === null ? null : (
                  <a
                    className="profile_icons"
                    href={profileData?.social_handles?.facebook}
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                )}

                {profileData?.social_handles?.instagram === null ? null : (
                  <a
                    className="profile_icons"
                    href={profileData?.social_handles?.instagram}
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                )}

                {profileData?.social_handles?.twitter === null ? null : (
                  <a
                    className="profile_icons"
                    href={profileData?.social_handles?.twitter}
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                )}

                {profileData?.social_handles?.snapchat === null ? null : (
                  <a
                    className="profile_icons"
                    href={profileData?.social_handles?.snapchat}
                  >
                    <i className="fa-brands fa-snapchat"></i>
                  </a>
                )}

                {profileData?.social_handles?.threads === null ? null : (
                  <a
                    className="profile_icons"
                    href={profileData?.social_handles?.threads}
                  >
                    <i className="fa-brands fa-threads"></i>
                  </a>
                )}

                {profileData?.social_handles?.telegram === null ? null : (
                  <a
                    className="profile_icons"
                    href={profileData?.social_handles?.telegram}
                  >
                    <i className="fa-brands fa-telegram"></i>
                  </a>
                )}

                {profileData?.social_handles?.whatsapp === null ? null : (
                  <a
                    className="profile_icons"
                    href={profileData?.social_handles?.whatsapp}
                  >
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                )}

                {profileData?.website === null ? null : (
                  <a className="profile_icons" href={profileData?.website}>
                    <i className="fa-solid fa-globe"></i>
                  </a>
                )}
              </div>

              <div className="profile_contact_info_container">
                {profileData?.contact_number ? (
                  <div className="profile_contact_info_section">
                    <i className="fa-solid fa-phone"></i>
                    <div className="profile_contact_info">
                      <p>{profileData?.contact_number}</p>
                    </div>
                  </div>
                ) : null}

                {profileData?.email ? (
                  <div className="profile_contact_info_section">
                    <i className="fa-solid fa-envelope"></i>
                    <div className="profile_contact_info">
                      <p>{profileData?.email}</p>
                    </div>
                  </div>
                ) : null}

                {profileData?.location ? (
                  <div className="profile_contact_info_section">
                    <i className="fa-solid fa-location-dot"></i>
                    <div className="profile_contact_info">
                      <p>{profileData?.location}</p>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="save_contact_btn_container mt-4">
                <button id="saveContactBtn" onClick={saveContactBtn}>
                  Save Contact
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ display: networkError && !notFoundError ? "block" : "none" }}
        >
          <NetworkError />
        </div>
        <div
          style={{ display: !networkError && notFoundError ? "block" : "none" }}
        >
          <PageNotFound page={"invalidId"} />
        </div>
      </div>
      <div style={{ display: loading ? "block" : "none" }}>
        <LoadingScreen />
      </div>
    </div>
  );
}
