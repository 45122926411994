import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../utils/applicationConstants";
import Avatar from "@mui/material/Avatar";
import "./Profile.css";

export default function ProfileImage(props) {
  const { profileImageUrl, userId } = props;
  const [preview, setPreview] = useState();

  const data = {
    authToken: "123",
    id: userId,
  };

  const getImage = async () => {
    const imageUrl = `${BASE_URL}/api/v1/profiles/profile-image/get`;
    try {
      const res = await axios.post(imageUrl, data, { responseType: "blob" });
      setPreview(URL.createObjectURL(res.data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <Avatar
      src={preview}
      sx={{ width: "150px", height: "150px", marginBottom: "30px" }}
    />
  );
}
